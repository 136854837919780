import getFilteredPercentileTableByMaxPercentile from "./GetFilteredPercentileTableByMaxPercentile";

const lessOrEqualMinimalMatchFunction = (value, pctTable) => value <= pctTable[0].v;

const percentileMatchFunction = (value, pctTable, index) => value <= pctTable[index].v && value > pctTable[index - 1].v

const calculateEtalAscending = ({ value, pctTable }) => {
    if (pctTable.length !== 8) {
        return {};
    }

    const filteredPercentileTableByMaxPercentile = getFilteredPercentileTableByMaxPercentile(pctTable);

    filteredPercentileTableByMaxPercentile.sort(({p:a}, {p:b}) => a-b);

    if (lessOrEqualMinimalMatchFunction(value, filteredPercentileTableByMaxPercentile)) {
        return {percentileValue: filteredPercentileTableByMaxPercentile[0].p, label: `${filteredPercentileTableByMaxPercentile[0].p}`, sign: "≤"};
    }

    const transformedFilteredPctTable = filteredPercentileTableByMaxPercentile.map(({p}, index) => ({matchFunction: percentileMatchFunction, result: {percentileValue: filteredPercentileTableByMaxPercentile[index].p, label: `${filteredPercentileTableByMaxPercentile[index].p}`, sign: '≤'}, index}));

    const found = transformedFilteredPctTable.find(({ matchFunction, index }) => matchFunction(value, filteredPercentileTableByMaxPercentile, index));
    return found ? found.result : transformedFilteredPctTable[transformedFilteredPctTable.length -1].result;
};

export default calculateEtalAscending;